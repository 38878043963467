
export default {
  data() {
    return {
      scrolled: false,
      lock: false,
      open: false,
    };
  },
  created() {
    if (this.$route.fullPath != "/") {
      this.scrolled = true;
      this.lock = true;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    open:function(val){
      if(val){
        document.body.style.overflow = "hidden";
      }else{
        document.body.style.overflow = "auto";
      }
    },
    $route(to) {
      this.open = false;
      if (to.fullPath == "/") {
        this.lock = false;
        this.handleScroll();
      } else {
        this.lock = true;
        this.scrolled = true;
      }
    },
  },
  methods: {
    handleScroll() {
      if (this.lock) {
        return;
      }
      if (window.scrollY > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
};
